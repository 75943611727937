<script>
import DatePicker from "vue2-datepicker"
import Layout from "../../layouts/main"
import PageHeader from "@/components/page-header"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx/xlsx.mjs'

import { getReport } from '@/services/ticket.service'
import { getOperators } from '@/services/operators.service'
import { 
    getUserParkings
} from '@/services/parkings.service'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  async mounted() {
    this.userParkings = await getUserParkings()
    this.parkingOptions = this.getParkingOptions()
    this.operators = await getOperators()
  },
  data() {
    return {
      operators: [],
      userParkings: [],
      parkingOptions: [],
      reportData: [],
      parking_id: "",
      daterange: "",
      title: "Tickets",
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Tickets",
          active: true
        }
      ]
    };
  },
  methods: {
    async buildReport() {
      const dates = this.daterange
      const startDate = new Date(dates[0])
      const endDate = new Date(dates[1])

      const startFormatDate = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
      const endFormatDate = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`

      const reportReq = await getReport({
        parking_id: this.parking_id,
        start: startFormatDate,
        end: endFormatDate
      })

      this.reportData = reportReq.data.tickets
    },
    getParkingOptions() {
      const parkingOptions = this.userParkings.map((parking) => { 
        return {
          value: parking._id, 
          text: parking.name
        }
      })

      return parkingOptions
    },
    getOperatorName(id) {
      console.log(id)
      return this.operators.filter(operator => operator._id === id)[0].operator_id
    },
    getStatus(ticket) {
      let status = 'ABIERTO'

      if(ticket.exit) {
        status = 'CERRADO'
      }
      return status
    },
    formatNumber(num) {
      if(num < 10) {
        return `0${num}`
      }
      return num
    },
    formatDate(input) {
      const d = new Date(input)

      return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}  ${this.formatNumber(d.getHours())}:${this.formatNumber(d.getMinutes())}`
    },
    formatTicketId(input) {
      return input.substr(input.length - 5).toUpperCase()
    },
    exportToExcel() {
      if (this.reportData.length > 0) {
        const table = document.getElementById('report-table')

        const workbook = XLSX.utils.table_to_book(table)
        const ws = workbook.Sheets["Facturas"]
        XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1})

        const s = this.formatDate(this.daterange[0])
        const e = this.formatDate(this.daterange[1])
        XLSX.writeFile(workbook, `tickets-${s}-${e}.xls`)
      } else {
        Swal.fire('error', 'No se ha generado ningun reporte')
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4">
                <div class="form-group mb-3">
                  <label>Seleccionar Parqueo</label>
                  <br />
                  <b-form-select
                    style="height: 34px;"
                    v-model="parking_id" 
                    :options="parkingOptions"></b-form-select>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="form-group mb-3">
                  <label>Date Range</label>
                  <br />
                  <date-picker v-model="daterange" range append-to-body lang="en" confirm></date-picker>
                </div>
              </div>
              <div class="col-xl-4">
                <br/>
                <a href="javascript:void(0);" class="btn btn-success btn-lg mb-2" @click="buildReport()">
                  Obtener Reporte
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <button @click="exportToExcel" class="btn btn-primary">
                  <i class="ri-file-excel-line"></i>
                  Excel
                </button>
              </div>
              <div class="col-xl-12">

                <div class="table-responsive mt-3">
              <table
                  id="report-table"
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Operador</th>
                    <th>Placa</th>
                    <th>Entrada</th>
                    <th>Salida</th>
                    <th>Monto (GTQ)</th>
                    <th>Status</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in reportData" :key="index">
                    <td>{{formatTicketId(item._id)}}</td>
                    <td>{{getOperatorName(item.operator_id)}}</td>
                    <td>{{item.plate}}</td>
                    <td>{{formatDate(item.entry)}}</td>
                    <td>{{formatDate(item.exit)}}</td>
                    <td>{{item.amount_due.toFixed(2)}}</td>
                    <td>{{getStatus(item)}}</td>
                    <td>{{item.type}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>